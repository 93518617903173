<template>
<!-- eslint-disable-next-line max-len -->
<v-dialog v-model="show" width="1200" maxWidth="1200" content-class="dialog-lot dialogLotDtl" :loading="loading">
  <v-card>
    <v-card-title>
      <!-- title -->
      <slot name="title">
        <div class="btn-area d-block">
          <!-- 뒤로 button -->
          <v-btn outlined rounded
            color="#fff"
            @click="opener('openLotDtl')"
            class="px-4"
          >
            <v-icon class="mr-1 white--text f12"> icon-hw_return </v-icon>
            뒤로
          </v-btn>
          <!-- 나의 응모 현황 button: 1. 블록체인 기반 사내추첨 리스트의 응모한 추첨 탭으로 이동 -->
          <v-btn outlined rounded
            color="#fff"
            @click="open('openLotList', 'winnerList')"
            class="ml-2 px-4"
          >
            <v-icon class="mr-1 f12"> icon-hw_fb72 </v-icon>
            나의 응모 현황
          </v-btn>
        </div>
        <div class="mt-6 d-flex align-items-center justify-space-between">
          <strong class="lot-title pt-2 ellipsis">
            {{editedItem.itemNm === undefined ? dummy.eventNm : editedItem.itemNm}}
          </strong>
          <!-- <div class="lot-dates pl-10 f18">응모기간 : 22.04.16 ~ 22.05.15</div> -->
        </div>
      </slot>

      <v-spacer></v-spacer>

    </v-card-title>
    <v-card-text>
      <!-- content -->
      <slot name="content">
        <v-card flat width="542" class="h-lot-dtl ma-auto">
          <v-card-title class="pt-15 justify-center text-center">
            <div><strong class="f26">
              <em>{{ empNm }}님</em><br>
              잔여토큰 개수는
              <span class="h-count primary--text">
                {{ rmnToken }}
              </span>개입니다
            </strong></div>
             <p class="pt-4 f16">응모토큰 범위 : {{ tokenMin }} ~ {{ tokenMax }}<br>응모할 토큰수를 선택 후 확인 버튼을 눌러주세요</p>
          </v-card-title>
          <v-card-text class="pa-0">
            <strong class="lot-dtl-title mb-3 mx-auto d-block">응모현황
              ( 현재 전체 응모 토큰 : {{editedItem.tokenSum === null ? '0' :  editedItem.tokenSum}})</strong>
            <v-simple-table
              class="lot-tbl-dtl rounded-0"
            >
              <template v-slot:default>
                <tbody>
                  <tr>
                    <th>이벤트 명</th>
                    <td>
                      <v-chip @click="detailInfo(editedItem)"
                        small color="#d4dbff"
                        text-color="#10207d"
                        :disabled="editedItem.detailInfo === '' && editedItem.files === ''"
                        class="mr-4 font-weight-light"> 상세설명 </v-chip>

                      <!-- <v-chip small color="#edf0ff" text-color="#10207d" class="mr-4 font-weight-light">추첨 1</v-chip> -->
                      <span class="font-weight-medium">
                        {{ editedItem.itemNm === undefined ? dummy.sttDate : editedItem.itemNm }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>응모 토큰 종류</th>
                    <td class="font-weight-medium">
                    <span class="font-weight-medium d-flex flex-row">
                      <span>
                        <v-img v-if="imageId.length > 0" :src="require(`@/assets/images/${imageId}`)" width="25" height="25" alt="토큰 아이콘" />
                        <v-img v-else src="@/assets/images/id_lottoken.png" width="25" height="25" alt="토큰 아이콘" />
                      </span>
                      <span class="ps-1" style="height:25px; display: grid; place-items: center;">
                        {{ editedItem.tokenName }}
                      </span>
                    </span>
                    </td>
                  </tr>
                  <tr>
                    <th>응모 토큰 수</th>
                    <td>
                      <v-select solo flat outlined hide-details
                        v-model="applicantTokenNum"
                        @change="select()"
                        :items="array"
                        value="1"
                        label="선택"
                        return-object
                        :menu-props="{ offsetY: true, contentClass: 'select-hj-srch' }"
                        append-icon="icon-hw_b-arrowdown f10"
                        class="h-select mt-0 pt-0 f13"
                      ></v-select>
                      <!-- eslint-disable-next-line vue/no-parsing-error-->
                      <span class="red--text f13">{{rmnToken < tokenMin ? '잔여토큰이 최수 응모개수보다 작습니다' :  ''}}</span>
                    </td>
                  </tr>
                  <tr v-if="editedItem.winnerNum <= 1">
                    <th>{{ empNm }}님의 당첨확률</th>
                    <td>
                      {{ probability }}%
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </slot>
    </v-card-text>
    <v-card-actions>
      <slot name="footer">
        <v-spacer></v-spacer>
        <v-btn outlined rounded
          color="#ccc"
          @click="close"
          class="px-4"
          :disabled="applyDisabled !== false"
        >
          <v-icon class="mr-1 black--text f12"> icon-m-ic-close </v-icon>
          취소
        </v-btn>
        <v-btn depressed rounded
          color="primary"
          @click="[ save(), ]"
          class="px-4 white--text"
          :disabled="applyDisabled !== false"
        >
          <v-icon class="mr-1 white--text f12"> icon-hw-ic-check3 </v-icon>
          확인
        </v-btn>
      </slot>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import { api } from '@/axios';

export default {
  name: 'DialogLotApply',
  components: {
  },
  props: {
    value: Boolean,
    loading: Boolean,
    showClose: {
      type: Boolean,
      default: true,
    },
    showSave: {
      type: Boolean,
      default: false,
    },
    openModal: Function,
    editedItem: {
      type: Object,
      // default: () => [],
    },
  },
  data() {
    return {
      submitFlag: false,
      probability: 0,
      dialog: false,
      applyDisabled: false,
      applyFrom: '',
      applyTo: '',
      tokenMax: 0,
      tokenMin: 0,
      array: [

      ],
      rmnToken: 0,
      applicantTokenNum: 0,
      applicantToken: 0,
      empNm: '',
      dummy: {
        eventNm: '거제 3차 르씨엘 예약 접수',
        evtDtlId: 'ed-09',
        applNum: '100',
        rmnToken: '3',
        stdToken: '200',
        winNum: '1',
        sttDate: '22.04.16',
        endDate: '22.05.15',
        applCd: '50',
        tokenId: '',
      },
      imageId: '',
      targetYear: '',
    };
  },
  computed: {
    show: {
      get() {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.empNm = localStorage.getItem('userNm');
        try {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.imageId = `${this.editedItem.tokenId.toLowerCase()}.png`;
        } catch (e) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.imageId = '';
        }
        this.fetchData();
        return this.value;
      },
      set() {
        this.$emit('input', false);
      },
    },
  },
  filters: {
  },
  mounted() {
  },
  watch: {
    applicantTokenNum(val) {
      if (val === '') {
        this.probability = 0;
      } else {
        if (this.editedItem.applicantNum < this.editedItem.winnerNum) {
          this.probability = 100.00;
          return;
        }
        // eslint-disable-next-line no-lonely-if
        if (this.editedItem.winnerNum === 1) {
          this.probability = ((val / ((this.editedItem.tokenSum + val) * 1).toFixed(4)) * 100).toFixed(2);
        } else {
          this.probability = (((this.editedItem.winnerNum)
                   * (val / (this.editedItem.tokenSum + val))
                   // eslint-disable-next-line no-restricted-properties
                   * (Math.pow((1 - (val / (this.editedItem.tokenSum + val))),
                     this.editedItem.winnerNum - 1))).toFixed(4) * 100).toFixed(2);
        }
      }
    },
    show() {
      const val = 1;
      if (this.editedItem.winnerNum === 1) {
        this.probability = ((1 / ((this.editedItem.tokenSum + 1) * 1).toFixed(4)) * 100).toFixed(2);
      } else {
        this.probability = (((this.editedItem.winnerNum)
                  * (val / (this.editedItem.tokenSum + val))
                  // eslint-disable-next-line no-restricted-properties
                  * (Math.pow((1 - (val / (this.editedItem.tokenSum + val))),
                    this.editedItem.winnerNum - 1))).toFixed(4) * 100).toFixed(2);
      }
    },
  },
  created() {
    this.$EventBus.$on('rmnToken', this.onReceive);
    this.$EventBus.$on('empNm', this.onReceiveName);
  },
  methods: {
    async fetchData() {
      if (this.editedItem.eventId !== undefined) {
        await api.get(`/event/get/${this.editedItem.eventId}`).then((res) => {
          console.log('[bk] fetch ', res);
          console.log('[bk] eventItem ', this.editedItem);
          this.applyTo = res.data.applyTo;
          this.tokenMax = res.data.tokenMax;
          this.tokenMin = res.data.tokenMin;
          this.targetYear = res.data.targetYear;
        });
      }
    },
    select() {
      this.applicantToken = this.applicantTokenNum;
    },
    opener(name) {
      // 임시 : modal 내 페이지 전환 필요
      this.initForm();
      this.$emit('openModal', name);
    },
    open(name, item) {
      // 임시 : modal 내 페이지 전환 필요
      this.initForm();
      this.$emit('openModal', name, item);
    },
    submitCheck() {
      if (this.submitFlag) {
        return this.submitFlag;
      // eslint-disable-next-line no-else-return
      } else {
        this.submitFlag = true;
        return false;
      }
    },
    async save() {
      if (this.editedItem.eventId === null || this.editedItem.eventId === undefined) {
        this.editedItem.eventId = localStorage.getItem('eventId');
      }
      if (this.applicantToken === 0 || this.applicantToken > this.rmnToken) {
        this.$dialog.warning({
          text: '응모할 토큰의 개수를 확인하여 주시기 바랍니다',
          title: ' Warning ',
        });
        return;
      }
      if (this.submitCheck()) {
        return;
      }

      const date = new Date();
      const year = date.getFullYear();
      const month = (`0${1 + date.getMonth()}`).slice(-2);
      const day = (`0${date.getDate()}`).slice(-2);
      const hour = (`0${date.getHours()}`).slice(-2);
      const min = (`0${date.getMinutes()}`).slice(-2);
      const today = `${year}-${month}-${day} ${hour}:${min}`;
      let returnFlag = true;
      if (this.applyTo < today) {
        this.$dialog.warning({
          text: '응모기간이 종료되어 응모할 수 없습니다.',
          title: ' Warning ',
        });
        this.initForm();
        this.$emit('openModal', 'openLotList', 'List');
        returnFlag = false;
      }
      if (returnFlag) {
        this.applyDisabled = true;
        await api({
          method: 'put',
          url: '/event/apply/register',
          data: {
            applicantToken: this.applicantToken,
            eventId: this.editedItem.eventId,
            itemId: this.editedItem.itemId,
            itemNm: this.editedItem.itemNm,
            userId: localStorage.getItem('userId'),
            positionNm: localStorage.getItem('job'),
            deptNm: localStorage.getItem('deptNm'),
            targetYear: this.targetYear,
          },
        }).then((response) => {
          if (response.status === 200) {
            this.$EventBus.$emit('tokenChanged', 'doUpdate');
          }
          this.applyDisabled = false;
          if (response.data.itemId === this.editedItem.itemId) {
            // let rmn = localStorage.getItem('rmnToken');
            // rmn -= this.applicantToken;
            // localStorage.setItem('rmnToken', rmn);
            this.$emit('openModal', 'openLotComplete', response.data);
          } else {
            console.log(response.data);
            this.$emit('openModal', 'openLotHistory', response.data);
          }
        });
        this.submitFlag = false;
        this.initForm();
      }
    },
    close() {
      this.initForm();
      this.$emit('openModal', 'openLotDtl', this.editedItem);
    },
    crfunction(detailInfo) {
      return detailInfo.split('\n').join('<br />');
    },
    detailInfo(item) {
      let info = '';
      if (item.detailInfo === '') {
        info = '상세설명이 없습니다.';
      } else {
        info = this.crfunction(item.detailInfo);
      }
      for (let i = 0; i < item.files.length; i++) {
        if (i % 4 === 0) {
          info += '<br />';
        }
        info += `<img src=/img${item.files[i].filePath} width=100 height=100 onclick='window.open(this.src)' title='클릭하면 확대됩니다.' />`;
      }
      this.$dialog.info({
        text: `${info}`,
        title: ' 상세설명 ',
      });
    },
    initForm() {
      this.applicantToken = 0;
      this.applicantTokenNum = '';
      this.probability = '0';
      this.show = false;
    },
    onReceive(rmnToken) {
      this.array = [];
      this.rmnToken = this.getRemainToken(rmnToken);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // this.rmnToken = localStorage.getItem('rmnToken') === null ? 0 : localStorage.getItem('rmnToken');
      let max = this.tokenMax;
      if (this.rmnToken < this.tokenMin) {
        return;
      }
      if (this.rmnToken < this.tokenMax) {
        max = this.rmnToken;
      }
      for (let i = this.tokenMin; i <= max; i++) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.array.push(i);
      }
    },
    getRemainToken(tokens) {
      // let id = this.editedItem.tokenId;
      for (let i = 0; i < tokens.length; ++i) {
        const token = tokens[i];
        if (token.type === this.editedItem.tokenId) {
          return token.count;
        }
      }
      return 0;
    },
    onReceiveName(empNm) {
      this.empNm = empNm;
    },
  },
};
</script>
