/* eslint-disable max-len */
<template>
<!-- eslint-disable-next-line max-len -->
<v-dialog v-model="show" width="1200" maxWidth="1200" content-class="dialog-lot dialogLotDtl" :loading="loading">
  <v-card>
    <v-card-title>
      <!-- title -->
      <slot name="title">
        <div class="btn-area d-block">
          <!-- 뒤로 button -->
          <v-btn outlined rounded
            color="#fff"
            @click="goBack()"
            class="px-4"
          >
            <v-icon class="mr-1 white--text f12"> icon-hw_return </v-icon>
            뒤로
          </v-btn>
          <!-- 나의 응모 현황 button: 1. 블록체인 기반 사내추첨 리스트의 응모한 추첨 탭으로 이동 -->
          <v-btn outlined rounded
            color="#fff"
            @click="opener('openLotList', 'winnerList')"
            class="ml-2 px-4"
          >
            <v-icon class="mr-1 f12"> icon-hw_fb72 </v-icon>
            나의 응모 현황
          </v-btn>
        </div>
        <div class="mt-6 d-flex align-items-center justify-space-between">
          <strong class="lot-title pt-2 ellipsis">
            <!-- index 페이지에서 팝업 오픈일 때 dummy -->
            {{ editedItem.eventNm === undefined ? dummy.eventNm : editedItem.eventNm }}
          </strong>
          <div class="lot-dates pl-10 f18">응모기간 :
            {{ editedItem.applyFrom === undefined ? dummy.sttDate : editedItem.applyFrom }}
            ~
            {{ editedItem.applyTo === undefined ? dummy.endDate : editedItem.applyTo }}
          </div>
        </div>
      </slot>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <!-- content -->
      <slot name="content">
        <v-card flat>
          <v-card-title class="pa-0 pt-6 pb-3 align-content-end justify-space-between f13">
            <div class="d-flex">
              <div class="h-count">
                <strong>
                  총 <span>{{ lists.length }}</span>
                </strong>
                건
              </div>
              <div class="ml-6" v-if="eventApplycd === true" >
                 내가 응모한 아이템 -
                 <span class="primary--text font-weight-bold"> {{ applyItemNm }} (당첨확률 : {{probability}}%) </span></div>
            </div>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-data-table
              :headers="headers"
              :items="lists"
              :items-per-page="5"
              :loading="loadingCheck"
              no-data-text="데이터가 없습니다."
            >
              <!-- 이벤트 상세명 -->
              <template #[`item.itemNm`]="{ item }" >
                <!-- eslint-disable -->
                <v-chip @click="detailInfo(item)"
                  small color="#d4dbff"
                  text-color="#10207d"
                  :disabled="item.detailInfo === '' && item.files.length === 0"
                  class="mr-4 font-weight-light"> 상세설명 </v-chip>
                <span class="font-weight-medium">{{ item.itemNm }}</span>
              </template>
              <!-- 응모인원 -->
              <template #[`item.applicantNum`]="{ item }">
                 {{ item.applicantNum !== undefined ? item.applicantNum :  '0'}}
              </template>
               <!-- 참여토큰 -->
              <template #[`item.token`]="{ item }">
                 {{item.tokenSum !== null ?  item.tokenSum : '0'}}
              </template>
               <!-- 나의 응모정보 -->
              <template #[`item.myToken`]="{ item }">
                <span> {{ item.applicantToken }}</span>
                 /{{ item.applicantNum === 0 || item.tokenSum === 0 ? '0' : (((item.applicantToken / item.tokenSum).toFixed(4))*100).toFixed(2) }}%
              </template>
              <!-- actions -->
              <template #[`item.actions`]="{ item }">
                <!-- Y:활성화 N:이미 응모한추첨(취소하기) '':비활성화-->
                <v-btn depressed rounded
                  :outlined="item.applyCd === 'Y'"
                  :color="item.applyCd === 'N' ? 'primary' : '#ccc'"
                  :disabled="item.anyApplied !== 'Y'"
                  height="32"
                  @click="opener(item.applyCd === 'N' ? 'openLotApply' : 'openLotCancel', item)"
                  class="action-btn font-weight-light f13"
                >
                  <v-icon class="mr-1"> {{ item.applyCd === 'N' ? 'icon-hw-ic-check3 f12' : 'icon-hw_close f10'}} </v-icon>
                  {{item.applyCd === 'Y' ? '취소하기' : '응모하기'}}
                </v-btn>
              </template>
            </v-data-table>

            <!-- pagination -->
            <div class="h-pagination pt-7 d-flex align-items-center justify-center f14">
              <!-- <strong>{{ page }}</strong > / {{ Math.ceil(lists.length / perPage) }}
              <v-pagination
                v-if="Math.ceil(lists.length / perPage) > 1"
                v-model="page"
                :length="Math.ceil(lists.length / perPage)"
                class="pl-2 d-flex"
              ></v-pagination> -->
            </div>

          </v-card-text>
        </v-card>
      </slot>
    </v-card-text>
    <v-card-actions>
      <slot name="footer">
        <v-spacer></v-spacer>
        <v-btn
          outlined rounded color="#ccc"
          @click="detailTab === '2' ? opener('openLotList','winnerList') : opener('openLotList')"
          class="px-4"
        >
          <v-icon class="mr-1 black--text f12"> icon-m-ic-close </v-icon>
          닫기
        </v-btn>
      </slot>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
// import ListCompnt from '@/views/dialog/ListCompnt.vue';
// import DtlCompnt from '@/views/dialog/DtlCompnt.vue';
import { api, abortBearer } from '@/axios';

export default {
  name: 'DialogLotList',
  components: {
    // ListCompnt,
    // DtlCompnt,
  },
  props: {
    value: Boolean,
    loading: Boolean,
    showClose: {
      type: Boolean,
      default: true,
    },
    showSave: {
      type: Boolean,
      default: false,
    },
    openModal: Function,
    editedItem: {
      type: Object,
      // default: () => [],
    },
  },
  data() {
    return {
      applyItemNm: '',
      eventApplycd: false,
      detailTab: 0,
      probability: 0,
      rmnToken: 0,
      cartoonDialog: false,
      dialog: false,
      editItem: this.editedItem,
      active: false,
      // table
      page: 1,
      perPage: 5,
      pageCount: 0,
      loadingCheck: true,
      pageList: [],
      disabled: true,
      headers: [
        { text: '상세 이벤트 명', align: 'left', value: 'itemNm', sortable: false, class: 'text-center h-event-title', width: '50%' },
        // { text: '응모인원', align: 'center', value: 'applicantNum', sortable: false, width: '12%', class: 'text-center' },
        // { text: '나의참여토큰/총응모토큰', align: 'center', value: 'token', sortable: false, width: '20%', class: 'text-center' },
        // { text: '응모 토큰 / 당첨 확률', align: 'center', value: 'myToken', sortable: false, width: '20%' },
        { text: '당첨인원', align: 'center', value: 'winnerNum', sortable: false, width: '12%' },
        { text: '', align: 'center', value: 'actions', sortable: false },
      ],
      lists: [],
      itemLists: [],
      applyLists: [],
      userApplyItem: [],
      dummy: {
        itemNm: '이벤트아이템',
        evtDtlId: 'ed-10',
        applicantNum: '100',
        applicantToken: '10',
        tokenSum: '200',
        winNum: '1',
        sttDate: '22.04.16',
        endDate: '22.05.15',
        applyCd: 'N',
        eventNm: '한화와 함께하는 2022 교향악 축제',
      },
      tokenHeader: { text: '총 응모토큰', align: 'center', value: 'token', sortable: false, width: '12%', class: 'text-center' },
      applicantHeader: { text: '응모인원', align: 'center', value: 'applicantNum', sortable: false, width: '12%', class: 'text-center' },
    };
  },
  computed: {
    show: {
      get() {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.detailTab = localStorage.getItem('detailTab');
        return this.value;
      },
      set() {
        this.$emit('input', false);
      },
    },
  },
  filters: {
  },
  watch: {
    rmnToken(newVal, oldVal) {
      if (newVal > oldVal) {
        this.eventApplycd = false;
      }
    },
    show(newVal) {
      if (newVal) {
        this.fetchData(this.editedItem);
      }
    },
  },
  created() {
    this.getRmnToken();
    this.$EventBus.$on('tokenChanged', this.getRmnToken);
  },
  methods: {
    async fetchData(item) {
      this.lists = [];
      this.loadingCheck = true;
      if (item.eventId !== 'undefined' && item.eventId !== undefined) {
        let tokenYnBool = true;
        let applicantNumBool = true;
        // 기존에 this.headers 에 value 에 token 이 있는지 없는지 확인, 있으면 false 값을 줘서 push 하지않음
        for (let i = 0; i < this.headers.length; i++) {
          if (this.headers[i].value === 'token') {
            tokenYnBool = false;
          }
          if (this.headers[i].value === 'applicantNum') {
            applicantNumBool = false;
          }
        }
        if (item.tokenYn === 'Y' && tokenYnBool) {
          this.headers.splice(1, 0, this.tokenHeader);
        } else if (item.tokenYn === 'N' && !tokenYnBool) {
          this.headers = this.headers.filter((data) => data.value !== 'token');
        }
        if (item.applicantYn === 'Y' && applicantNumBool) {
          this.headers.splice(1, 0, this.applicantHeader);
        } else if (item.applicantYn === 'N' && !applicantNumBool) {
          this.headers = this.headers.filter((data) => data.value !== 'applicantNum');
        }
        // this.controller = new AbortController();
        const controller = abortBearer.newAbortController();
        await api.post('/event/eventitem/get/items', {
          eventId: item.eventId,
          userId: localStorage.getItem('userId'),
        // }, { signal: this.controller.signal }).then((res) => {
        }, { signal: controller.signal }).then((res) => {
        // }).then((res) => {
          this.lists = res.data;
          console.log(item);
          // this.calculateProbability(res.data);
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].applyCd === 'Y') {
              this.applyItemNm = res.data[i].itemNm;
              this.eventApplycd = true;
              this.calculateProbability(res.data[i].applicantToken, res.data[i].applicantNum, res.data[i].tokenSum, res.data[i].winnerNum);
              break;
            } else {
              this.eventApplycd = false;
            }
          }
          console.log('[BK] LIST ', this.lists);
          // this.lists = res.data;
          this.loadingCheck = false;
        }).catch((e) => {
          console.log(e);
        }).finally((e) => {
          console.log('finally', e);
          abortBearer.removeController(controller);
        });
      }
    },
    calculateProbability(appliycantToken, applicantNum, tokenSum, winnerNum) {
      if (appliycantToken !== '') {
        if (applicantNum <= winnerNum) {
          this.probability = 100.00;
          return;
        }
        if (winnerNum === 1) {
          this.probability = (((appliycantToken / tokenSum).toFixed(4)) * 100).toFixed(2);
        } else {
          this.eventApplycd = false;
          // this.probability = (((winnerNum)
          //       * (appliycantToken / tokenSum)
          //       // eslint-disable-next-line no-restricted-properties
          //       * (Math.pow((1 - (appliycantToken / tokenSum)), winnerNum - 1))).toFixed(4) * 100).toFixed(2);
        }
      }
    },
    rowClasses(item) {
      if (this.userApplyItem.length === 0) {
        // 응모하기
        item.applyCd = 'N';
      } else if (this.userApplyItem[0].itemId === item.itemId) {
        // 취소하기
        item.applyCd = 'Y';
        item.applicantToken = this.userApplyItem[0].applicantToken;
        this.eventApplycd = true;
        if (item.applicantToken !== '') {
          this.probability = (((item.applicantToken / item.tokenSum).toFixed(4)) * 100).toFixed(2);
        }
        this.applyItemNm = item.itemNm;
      } else {
        item.applyCd = '';
      }
      let itemCheck = false;
      for (let i = 0; i < this.applyLists.length; i++) {
        if (this.applyLists[i].itemId === item.itemId) {
          item.tokenSum = this.applyLists[i].tokenSum;
          item.applicantNum = this.applyLists[i].applicantNum;
          itemCheck = true;
          return;
        }
      }
      if (itemCheck === false) {
        item.tokenSum = 0;
        item.applicantNum = 0;
      }
    },
    async getRmnToken() {
      await api.get(`/event/token/mytokens/${localStorage.getItem('userId')}`).then((res) => {
        console.log('[BK] REMAIN TOKENS', res.data);
        this.$EventBus.$emit('rmnToken', res.data);
        this.rmnToken = res.data;
        localStorage.setItem('rmnToken', this.rmnToken);
      });
    },
    opener(name, item) {
      this.getRmnToken();
      // 임시 : modal 내 페이지 전환 필요
      this.lists = [];
      this.show = false;
      this.eventApplycd = false;
      abortBearer.abort();
      console.log(`[BK] OPEN MODAL ${name}`, item);
      this.$emit('openModal', name, item);
    },
    save() {
      this.show = false;
      this.eventApplycd = false;
    },
    close() {
      this.show = false;
      this.eventApplycd = false;
    },
    crfunction(detailInfo) {
      return detailInfo.split('\n').join('<br />');
    },
    detailInfo(item) {
      let info = '';
      if (item.detailInfo === '') {
        info = '상세설명이 없습니다.';
      } else {
        info = this.crfunction(item.detailInfo);
      }
      for (let i = 0; i < item.files.length; i++) {
        if (i % 4 === 0) {
          info += '<br />';
        }
        info += `<img src=/img${item.files[i].filePath} width=100 height=100 onclick='window.open(this.src)' title='클릭하면 확대됩니다.' />`;
      }
      console.log(info);
      this.$dialog.info({
        text: `${info}`,
        title: ' 상세설명 ',
      });
    },
    goBack() {
      abortBearer.abort();
      this.opener('openLotList');
    },
  },
};
</script>
