<template>
  <!-- eslint-disable-next-line max-len -->
  <v-dialog v-model="show" width="1200" maxWidth="1200" content-class="dialog-lot dialogLotList" :loading="loading">
    <v-card>
      <v-card-title>
        <!-- title -->
        <slot name="title" class="d-flex">
          <div class="flex-fill">
            <strong class="pt-2">
              <em>블록체인 기반</em> <br/>
              사내추첨<br>
            </strong>
          </div>
          <div class="align-content-center f13 card-content justify-center"
               @click="dialogMyTokens">
            <div class="ma-0">
              <div class="align-content-center pb-5">
                <v-btn
                    class="mb-1 mr-0 pa-0"
                    outlined
                    color="white"
                >
                <v-icon color="#6771f5" class="mdi-48px">mdi-account-circle</v-icon>
                </v-btn>
                <div class="text-center line-height-normal" style="font-size: x-small;  color: #5d5d5d">내 토큰</div>
              </div>
            </div>
          </div>
          <div class="align-content-center f13 card-content justify-center"
               @click="dialogMyNFTs">
            <div class="">
            <div class="align-content-center pb-5">
                <v-btn
                    class="mb-1 ml-0 pa-0"
                    outlined
                    color="white"
                >
                <v-icon
                    color="#6771f5"
                    class="mdi-48px"
                >mdi-trophy-outline</v-icon></v-btn>
              <div class="text-center line-height-normal" style="font-size: x-small; color: #5d5d5d">내 배지</div>
            </div>
          </div>
          </div>
        </slot>
      </v-card-title>
      <v-card-text>
        <!-- content -->
        <slot name="content">
          <v-tabs centered v-model="tabIndex" class="h-bs-tabs">
            <div class="tab-wrap mb-5">
              <v-tab height="52" v-for="(tab, idx) in tabs" :key="`tab-${idx}`" class="px-13 rounded-xl f15">
                {{ tab.title }}
              </v-tab>
            </div>
          </v-tabs>

          <v-tabs-items v-model="tabIndex">
            <v-tab-item v-for="(tab, idx) in tabs" :key="`tab-${idx}`" transition="false">
              <v-card flat>
                <v-card-title class="pa-0 pt-6 pb-3 align-content-end justify-space-between f13">
                  <div class="d-flex">
                    <div class="h-count">
                      <strong
                      >총
                        <span>{{ lists.length }}</span>
                      </strong>
                      건
                    </div>
                    <!-- 완료된 추첨 && card & list type 일 때 -->
                    <v-select
                        hide-details
                        v-if="tabIndex === 1"
                        v-model="selectYear"
                        @change="select"
                        :items="yearList"
                        value="2022"
                        label="선택"
                        return-object
                        :menu-props="{ offsetY: true, contentClass: 'select-hj-srch' }"
                        class="h-select mt-0 ml-5 pt-0 f13"
                    ></v-select>
                  </div>

                  <div class="btn-group border-collapse d-flex align-items-center">
                    <!-- view: list buttons -->
                    <v-btn icon depressed small @click="changeListView('list')"
                           :class="[{ active: listType === 'list' }]">
                      <v-icon class="f13"> icon-hw_list</v-icon>
                    </v-btn>
                    <!-- view: card buttons -->
                    <v-btn icon depressed small @click="changeListView('card')"
                           :class="[{ active: listType === 'card' }]">
                      <v-icon class="f13"> icon-hw_ic-file</v-icon>
                    </v-btn>
                  </div>
                </v-card-title>

                <v-card-text class="pa-0">
                  <!-- view: list -->
                  <template v-if="listType === 'list'">
                    <v-data-table
                        :headers="headers"
                        :items="lists"
                        :page.sync="page"
                        hide-default-footer
                        :items-per-page="perPage"
                        @page-count="pageCount = $event"
                        @click:row="nextOpen"
                        no-data-text="데이터가 없습니다."
                        class="font-weight-medium"
                        :loading="loadingCard"
                        loading-text="불러오는 중..."
                        style="cursor: pointer"
                    >
                      <template #[`item.termDates`]="{ item }">
                        <span class="font-weight-regular">{{ item.applyFrom }} ~ {{ item.applyTo }}</span>
                      </template>
                    </v-data-table>
                    <!-- pagination -->
                    <div class="h-pagination pt-2 d-flex align-items-center justify-center f14">
<!--                      <strong>{{ page }}</strong> / {{ Math.ceil(lists.length / perPage) }}-->
                      <v-pagination
                          v-if="Math.ceil(lists.length / perPage) > 1"
                          v-model="page"
                          :length="Math.ceil(lists.length / perPage)"
                          @input="updatePage"
                          class="pl-2 d-flex"
                          total-visible="8"
                      ></v-pagination>
                    </div>
                  </template>

                  <!-- view: card -->
                  <template v-else>
                    <span v-if="lists.length > 0">
                      <div class="inner-rowwrap pb-4 overflow-auto">
                        <v-card v-for="(item, idx) in pageList"
                                :key="`${'lot-' + idx}`"
                                :class="['card-wrap py-7 px-5 d-flex justify-space-between',
                                { 'lot-complete': beforeDate(item.applyTo) || tabIndex === 1}]"
                                outlined
                                rounded="lg"
                                @click="nextOpen(item)"
                        >
                          <div class="icon-chip rounded">
                            <v-icon>{{ item.icon === null ? 'icon-hw_fb13' : item.icon }}</v-icon>
                          </div>

                          <div class="card-content">
                            <strong class="lot-title pa-0 f16 ">{{ item.eventNm }}</strong>
                            <span class="lot-dates pt-3 font-weight-regular">{{ item.applyFrom }}
                             <br> ~ {{ item.applyTo }}</span>
                            <div class="btn-area pt-3 d-flex justify-end">
                              <v-icon class="f14">icon-hw_arrowright-light</v-icon>
                            </div>
                          </div>
                          <template slot="progress">
                            <v-progress-linear color="red" indeterminate></v-progress-linear>
                          </template>
                        </v-card>
                      </div>
                    </span>
                    <span v-else-if="loadingCard" class="d-flex justify-center font-weight-regular pt-8 pb-8">
                      <v-progress-circular
                          :size="50"
                          color="grey"
                          indeterminate
                      ></v-progress-circular>
                    </span>
                    <span v-else-if="lists.length === 0" class="d-flex justify-center font-weight-regular pt-8 pb-8">
                        <strong class="grey--text">데이터가 없습니다.</strong>
                    </span>
                    <!-- pagination -->
                    <div class="h-pagination pt-2 d-flex align-items-center justify-center f14">
<!--                      <strong>{{ page }}</strong> / {{ pages }}-->
                      <v-pagination
                          v-if="Math.ceil(lists.length / perPage) > 1"
                          v-model="page"
                          :length="Math.ceil(lists.length / perPage)"
                          @input="updatePage"
                          total-visible="8"
                          class="pl-2 d-flex"
                      ></v-pagination>
                    </div>
                  </template>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </slot>
        <!----

        내 배지 정보 모달

        ---->
        <v-container class="pa-0 align-content-center align-center">
          <modal-window
              v-model="modalMyNFTs"
              width="750"
              maxWidth="750"
              :showClose="true"
              :showFooter="false"
              content-class="dialogExcelUpload job-footer"
              title="내 배지 정보"
          >
            <template #content>
              <span
                  v-if="!loadingMyNFTs && myNFTs.length === 0"
                  class="d-flex justify-center font-weight-regular pt-8 pb-8"
                  style="height: 350px"
              >
<!--                <v-card outlined class="text-center border-style-dotted color_grey_4_border_border" style="padding-top: 100px; width: 330px">-->
                <v-card flat class="align-center">
                  <v-card></v-card>
                  <v-img
                      :src="require(`@/assets/images/empty_image001_grey.png`)"
                      width="210"
                      height="210"
                      color="grey"
                      class="ml-5"
                  >
                  </v-img>
                  <v-card flat class="pt-3 text-center grey--text">
                  <span class="">소유한 배지가 없습니다.</span>
                  <br/>
                  <span class="">다음 이벤트에서 배지 획득에 도전해보세요.</span>
                  </v-card>
                </v-card>
              </span>
              <span v-else-if="loadingMyNFTs" class="d-flex justify-center font-weight-regular pt-8 pb-8">
                <v-progress-circular
                    :size="50"
                    color="grey"
                    indeterminate
                ></v-progress-circular>
              </span>
              <template v-else>
                <v-spacer></v-spacer>
                <v-card flat>
                  <v-card-title class="align-content-end justify-space-between f13">
                    <div>
                    </div>
                    <!-- eslint-disable-next-line max-len -->
                    <div class="btn-group border-collapse d-flex">
                      <!-- view: list buttons -->
                      <v-btn icon depressed small @click="changeBadgeList('list')"
                             :class="[{ active: myNFTListType === 'list' }]">
                        <v-icon class="f13">mdi-view-week</v-icon>
                      </v-btn>
                      <!-- view: card buttons -->
                      <v-btn icon depressed small @click="changeBadgeList('card')"
                             :class="[{ active: myNFTListType === 'card' }]">
                        <v-icon class="f13">mdi-grid</v-icon>
                      </v-btn>
                    </div>
                  </v-card-title>
                </v-card>
                  <v-sheet
                      v-if="myNFTListType === 'list'"
                      class="pt-0 mt-0 mx-auto"
                      max-width="800"
                      max-height="550"
                      color="white"
                      outlined
                  >
                    <v-slide-group
                        v-model="myNFTs"
                        active-class="success"
                        center-active
                        show-arrows="always"
                    >
                      <v-slide-item
                          v-for="(item, idx) in myNFTs"
                          :key="`sld-${idx}`"
                      >
                        <v-container class="row--dense">
                          <v-card
                              class="mx-auto my-12"
                              max-width="250"
                              height="auto"
                              v-if="item.metadata"
                          >
                            <v-row
                                class="fill-height"
                            >
                              <v-img
                                  v-if="item.metadata.image"
                                  class="cursor-pointer align-content-center flex-fill"
                                  height="185"
                                  :src="item.metadata.image"
                                  @click="goToUrl(item.metadata.image)"
                              >
                                <template v-slot:placeholder>
                                  <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                  >
                                    <v-progress-circular
                                        indeterminate
                                        color="primary lighten-5"
                                    ></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                              <v-img v-else>
                                <v-card color="#f4f3f3" style="width: 250px; height: 185px">
                                  <v-icon
                                      color="#bcbcbc"
                                      class="mdi-48px"
                                      style="padding-top: 75px; padding-left: 100px"
                                  >
                                    mdi-comment-remove-outline
                                  </v-icon>
                                </v-card>
                              </v-img>
                              <v-card style="width: 250px; height: 150px">
                                <v-card-title class="pb-3 font-style-normal">
                                  NFT Contract
                                  <v-icon @click="goToUrl(item.tokenScope)" class="mdi-18px">mdi-open-in-new</v-icon>
                                </v-card-title>
                                <v-card-text>
                                  <div class="cursor-pointer" @click="goToUrl(item.tokenUri)">
                                    <div class="text-caption black--text">{{ item.eventName }}</div>
                                    <div v-if="item.metadata" class="font-weight-bold">{{ item.metadata.name }}</div>
                                    <div class="font-weight-light text-caption">#{{ item.tokenId }}</div>
                                  </div>
                                </v-card-text>
                              </v-card>
                            </v-row>
                          </v-card>
                        </v-container>
                      </v-slide-item>
                    </v-slide-group>
                  </v-sheet>
                <template v-else>
                  <v-container fluid>
                    <v-row class="pa-0">
                      <v-col
                          v-for="(item, idx) in myNFTs"
                          :key="`card-${idx}`"
                          cols="12"
                          md="4"
                      >
                        <v-card
                            class="mx-auto"
                            max-width="374"
                            height="auto"
                            v-if="item.metadata"
                        >
                          <template slot="progress">
                            <v-progress-linear
                                color="deep-purple"
                                height="10"
                                indeterminate
                            ></v-progress-linear>
                          </template>
                          <v-img
                              v-if="item.metadata.image"
                              class="cursor-pointer"
                              height="155"
                              :src="item.metadata.image"
                              @click="goToUrl(item.metadata.image)"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                              >
                                <v-progress-circular
                                    indeterminate
                                    color="primary lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                          <v-img v-else>
                            <v-card color="#f4f3f3" style="width: 100%" class="flex-fill" height="155">
                              <v-icon
                                  color="#bcbcbc"
                                  class="mdi-48px"
                                  style="padding-top: 50px; padding-left: 40%"
                              >
                                mdi-comment-remove-outline
                              </v-icon>
                            </v-card>
                          </v-img>
                          <v-card-text v-if="item.metadata">
                            <div>
                              <div class="font-weight-bold">{{ item.metadata.name }}</div>
                              <div class="font-weight-light">#{{item.tokenId}}
                                <v-icon class="mdi-18px" @click="goToUrl(item.tokenUri)">mdi-open-in-new</v-icon></div>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
              </template>
            </template>
          </modal-window>
        </v-container>
        <!----

        내 토큰 정보 모달

        ---->
        <v-container class="pa-0 align-content-center align-center">
          <!-- eslint-disable-next-line max-len -->
          <modal-window
              v-model="modalMyTokens"
              width="596"
              maxWidth="596"
              :showClose="true"
              :showFooter="false"
              content-class="dialogExcelUpload job-footer"
              title="내 토큰 정보"
              :loading="loading"
          >
            <template #content>
              <template>
                <v-spacer></v-spacer>
                <v-data-table
                    :headers="myTokensHeaders"
                    :items="myTokens"
                    hide-default-footer
                    no-data-text="데이터가 없습니다."
                    class="font-weight-medium mt-3"
                    :loading="loadingMyTokens"
                    loading-text="불러오는 중..."
                >
                  <template v-slot:item.name="{ item }">
                    <span class="font-weight-medium d-flex flex-row pl-15">
                      <span>
                        <v-img
                            :src="require(`@/assets/images/${item.type.toLowerCase()}.png`)"
                            width="25"
                            height="25"
                            alt="토큰 아이콘"
                        />
                      </span>
                      <span class="ps-4" style="height:25px; display: grid; place-items: center;">
                        {{ item.name }}
                      </span>
                    </span>
                  </template>
                </v-data-table>
              </template>
            </template>
          </modal-window>
        </v-container>
      </v-card-text>
      <v-footer class="grey--text">
        <v-card-text class="text-right v-size--small">
          <span @click="openPDF" class="cursor-pointer">개인정보처리방침</span>
        </v-card-text>
      </v-footer>
    </v-card>
  </v-dialog>
</template>

<script>
// import dayjs from 'dayjs';
// import jwtDecode from 'jwt-decode';
import { api } from '@/axios';
// import storage from '../../common/storage';

export default {
  name: 'DialogLotList',
  components: {},
  props: {
    value: Boolean,
    loading: Boolean,
    showClose: {
      type: Boolean,
      default: true,
    },
    showSave: {
      type: Boolean,
      default: false,
    },
    openModal: Function,
    editedItem: {
      type: Object,
      // default: () => [],
    },
    eventId: {
      type: String,
    },
  },
  data() {
    return {
      loadingCard: true,
      loadingMyTokens: true,
      loadingMyNFTs: true,
      selectYear: new Date().getFullYear().toString(),
      yearList: [],
      tabIndex: parseInt(localStorage.getItem('tab'), 10),
      nowDate: '',
      timer: null,
      tabs: [
        { id: '1', title: '진행중인 추첨' },
        { id: '2', title: '완료된 추첨' },
        { id: '3', title: '응모한 추첨' },
      ],

      // table
      listType: localStorage.getItem('listType') || 'card',
      page: 1,
      total: 0,
      perPage: 5,
      pageCount: 0,

      userId: '',

      pageList: [],

      headers: [
        { text: '이벤트명', align: 'left', value: 'eventNm', sortable: false, class: 'text-center' },
        { text: '응모기간', align: 'center', value: 'termDates', sortable: false, width: '33%' },
      ],
      // icon-hw_fb13 :: TO-DO 카테고리 별 아이콘 적용 예정
      lists: [
        // { eventId: 'evt-01', icon: 'icon-hw_fb100', eventNm: '거제 4차 르씨엘 예약 접수', sttDate: '2022.04.16', endDate: '2022.05.15' },
      ],
      modalMyTokens: false,
      myTokens: [],
      myTokensHeaders: [
        { text: '토큰 명', align: 'center', value: 'name', sortable: false },
        { text: '잔여 개수', align: 'center', value: 'count', sortable: false, width: '50%' },
      ],
      modalMyNFTs: false,
      myNFTs: [],
      model: null,
      myNFTListType: 'list',
      contractAddress: '',
    };
  },
  computed: {
    pages() {
      if (this.perPage == null || this.pageCount == null) {
        return 0;
      }
      return Math.ceil(this.pageCount / this.perPage);
    },
    show: {
      get() {
        return this.value;
      },
      set() {
        localStorage.setItem('tab', '0');
        this.$emit('input', false);
      },
    },
  },
  filters: {},
  mounted() {
    this.tabIndex = parseInt(localStorage.getItem('tab'), 10);
    const currentYear = new Date().getFullYear();
    // const currentYear = 2030;
    const limit = 5; // 완료된 추첨에서 화면에 출력해야할 연도 목록의 최대 수
    let firstYear = 2022;
    if (process.env.NODE_ENV !== 'production') {
      firstYear = 2020;
    }
    if (firstYear + limit < currentYear) {
      firstYear = currentYear - limit + 1;
    }
    for (let year = firstYear; year <= currentYear; ++year) {
      this.yearList.unshift(year.toString());
    }
  },
  watch: {
    tabIndex(v) {
      console.log('v === ', v);
      this.tabIndex = v;
      this.lists = [];
      this.page = 1;
      this.loadingCard = true;
      localStorage.setItem('detailTab', v);
      this.initPage();
    },
  },
  created() {
    this.initPage();
    this.$EventBus.$on('userId', this.onReceiveUserId);
    window.addEventListener('popstate', (event) => {
      if (!event.state) {
        this.$router.push({ name: 'main' });
      }
    });
  },
  methods: {
    async initPage() {
      this.updatePage(this.page);
      if (this.tabIndex !== 2) {
        let year = '';
        if (this.tabIndex === 1) {
          year = this.selectYear;
        }
        await api.post('/event/getlist', { reqType: this.tabIndex, selectYear: year }).then((res) => {
          this.lists = res.data;
          this.loadingCard = false;
        });
      } else {
        await api.get(`/event/apply/mylist/${localStorage.getItem('userId')}`).then((res) => {
          // console.log(res.data);
          this.lists = res.data;
          this.loadingCard = false;
        });
      }
      this.pageCount = this.lists.length;
      console.log(this.pageCount < this.perPage);
      if (this.pageCount < this.perPage) {
        this.pageList = this.lists;
      } else {
        this.pageList = this.lists.slice(0, this.perPage);
      }
      if (this.$route.params.eventId !== null && this.$route.params.eventId !== undefined) {
        const selectedEvent = this.lists.find((v) => v.eventId === this.$route.params.eventId * 1);
        this.nextOpen(selectedEvent);
      }
    },
    beforeDate(itemDate) {
      const date = new Date();
      const year = date.getFullYear();
      const month = (`0${1 + date.getMonth()}`).slice(-2);
      const day = (`0${date.getDate()}`).slice(-2);
      const hour = (`0${date.getHours()}`).slice(-2);
      const min = (`0${date.getMinutes()}`).slice(-2);
      const today = `${year}-${month}-${day} ${hour}:${min}`;
      return itemDate < today;
    },
    select() {
      this.initPage();
    },
    updatePage(pageIndex) {
      const _start = (pageIndex - 1) * this.perPage;
      const _end = _start + this.perPage;
      this.pageList = this.lists.slice(_start, _end);

      console.log(_start, this.pages, this.pageList);
      this.page = pageIndex;
    },
    changeListView(name) {
      this.listType = name;
    },
    opener(name, item) {
      // 임시 : modal 내 페이지 전환 필요
      this.show = false;
      this.$emit('openModal', name, item);
    },
    nextOpen(item) {
      let sEventId = '';
      if (item === null || item === undefined || item === 'undefined') {
        sEventId = this.$route.params.eventId;
        this.$route.params.eventId = null;
      } else {
        sEventId = item.eventId;
        this.$route.params.eventId = null;
      }
      localStorage.setItem('listType', this.listType);
      api.get(`/event/get/${sEventId}`).then((res) => {
        const date = new Date();
        const year = date.getFullYear();
        const month = (`0${1 + date.getMonth()}`).slice(-2);
        const day = (`0${date.getDate()}`).slice(-2);
        const hour = (`0${date.getHours()}`).slice(-2);
        const min = (`0${date.getMinutes()}`).slice(-2);
        const today = `${year}-${month}-${day} ${hour}:${min}`;
        const role = localStorage.getItem('role');
        // 응모기간 종료일이 오늘보다 크거나 같을 경우
        if (res.data.applyTo > today) {
          if (res.data.drawingYn === 'Y') {
            this.$emit('openModal', 'openLotWinnerList', res.data);
          } else if (res.data.applyFrom > today && role === 'HBC_USER') {
            alert(`[${res.data.eventNm}] 이벤트는 ${this.remainingTime(res.data.applyFrom)}이후 시작 됩니다.`);
          } else {
            this.$emit('openModal', 'openLotDtl', res.data);
          }
        } else { // 응모기간 종료일이 오늘보다 작을 경우
          this.$emit('openModal', 'openLotWinnerList', res.data);
        }

        // const applyEndDt = new Date(res.data.applyTo);
        // applyEndDt.setDate(applyEndDt.getDate() + 1);

        // if (new Date(res.data.applyFrom) < Date.now()) {
        //   if (res.data.drawingYn === 'Y') {
        //     this.$emit('openModal', 'openLotWinnerList', res.data);
        //   } else if (applyEndDt <= Date.now()) {
        //     this.$emit('openModal', 'openLotWinnerList', res.data);
        //   } else {
        //     this.$emit('openModal', 'openLotDtl', res.data);
        //   }
        // }
        // if (this.tabIndex === 0) {
        //   this.$emit('openModal', 'openLotDtl', res.data);
        // } else if (this.tabIndex === 1) {
        //   this.$emit('openModal', 'openLotWinnerList', res.data);
        // } else {
        //   // eslint-disable-next-line no-lonely-if
        //   if (res.data.drawingYn === 'N') {
        //     this.$emit('openModal', 'openLotDtl', res.data);
        //   } else {
        //     this.$emit('openModal', 'openLotWinnerList', res.data);
        //   }
        // }
      });
    },
    save() {
      this.show = false;
    },
    close() {
      this.show = false;
    },
    onReceiveUserId() {
      this.userId = localStorage.getItem('userId');
    },
    dialogMyTokens() {
      console.log('[BK] SHOW MY TOKENS');
      this.loadingMyTokens = true;
      api.get(`/event/token/mytokens/${localStorage.getItem('userId')}`)
        .then((res) => {
          console.log('[BK] MY TOKENS', res.data);
          if (res.status === 200) {
            this.myTokens = res.data;
          } else {
            // eslint-disable-next-line no-throw-literal
            throw `상태 코드 (${res.status})`;
          }
        })
        .catch((e) => {
          this.$dialog.warning({
            text: `오류가 발생했습니다. ${e.message}`,
          });
        })
        .finally(() => {
          this.loadingMyTokens = false;
        });
      this.openModalWindow('modalMyTokens');
    },
    async dialogMyNFTs() {
      this.openModalWindow('modalMyNFTs');
      console.log('[BK] SHOW MY NFTs');
      await api.get(`/event/token/nfts/${process.env.VUE_APP_NFT_ALIAS}/owner/${localStorage.getItem('userId')}`)
        .then(async (res) => {
          console.log('[BK] MY NFTs', res.data);
          if (res.status === 200) {
            this.myNFTs = res.data.data;
            /* 여러개의 다른 NFT 이미지 크기 조정 테스트 */
            // this.myNFTs = [];
            // const obj = {};
            // Object.assign(obj, this.myNFTs[0]);
            // this.myNFTs.push(obj);
            // const obj2 = {};
            // Object.assign(obj2, this.myNFTs[0]);
            // this.myNFTs.push(obj2);
            // const obj3 = {};
            // Object.assign(obj3, this.myNFTs[0]);
            // this.myNFTs.push(obj3);
            this.contractAddress = res.data.contractAddress;
            for (let i = 0; i < this.myNFTs.length; i++) {
              const url = this.myNFTs[i].tokenUri;
              // eslint-disable-next-line no-await-in-loop
              this.myNFTs[i].metadata = await this.getMetaData(url);
              // 현재 로컬 시간으로 포멧 변경
              // this.myNFTs[i].createdAt = new Date(this.myNFTs[i].createdAt).toLocaleString();
              // hex to dec
              this.myNFTs[i].tokenId = parseInt(this.myNFTs[i].tokenId, 16);
              /* TEST image URL
              if (i === this.myNFTs.length - 1) {
                this.myNFTs[i].metadata.image = '';
                this.myNFTs[i].updatedAt = 10000000000000000000000000000;
              }
              if (i === this.myNFTs.length - 2) {
                this.myNFTs[i].metadata.image = 'https://apod.nasa.gov/apod/image/2206/SolSysPortrait_Trigo_1920.jpg';
              }
              */
              this.myNFTs[i].tokenScope = `${process.env.VUE_APP_KLAYTN_SCOPE_URL}/nft/${this.contractAddress}/${this.myNFTs[i].tokenId}?tabId=nftTransfer`;
              this.loadingMyNFTs = false;
            }

            /* 여러개의 다른 NFT 이미지 크기 조정 테스트 */
            // this.myNFTs[2].metadata.image = '';
            // this.myNFTs[3].metadata.image = 'https://apod.nasa.gov/apod/image/2206/SolSysPortrait_Trigo_1920.jpg';

            // 업데이트 최신 순으로 정렬
            this.myNFTs.sort((a, b) => b.updatedAt - a.updatedAt);
            // this.myNFTs = [];
          } else {
            this.loadingMyNFTs = false;
            // eslint-disable-next-line no-throw-literal
            throw `상태 코드 (${res.status})`;
          }
          console.log('[this.myNFTs]');
          console.log(this.myNFTs);
        })
        .catch((e) => {
          this.loadingMyNFTs = false;
          this.$dialog.warning({
            text: `오류가 발생했습니다. ${e.message}`,
            confirm() {
              this.loadingMyNFTs = false;
            },
          });
        })
        .finally(() => {
          this.loadingMyNFTs = false;
        });
    },
    openModalWindow(name) {
      this.$data[name] = true;
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.loading = false), 1500);
    },
    remainingTime(target) {
      const today = new Date();
      const targetDate = new Date(target);
      const diff = targetDate - today;
      const diffDay = String(Math.floor(diff / (1000 * 60 * 60 * 24)));
      const diffHour = String(Math.floor((diff / (1000 * 60 * 60)) % 24));
      const diffMin = String(Math.floor((diff / (1000 * 60)) % 60)).padStart(2, '0');
      // eslint-disable-next-line no-mixed-operators
      const diffSec = String(Math.floor(diff / 1000 % 60)).padStart(2, '0');
      let diffDate = '';
      if (diffDay > 0) {
        diffDate += `${diffDay}일 `;
      }
      if (diffHour > 0) {
        diffDate += `${diffHour}시간 `;
      }
      if (diffMin > 0) {
        diffDate += `${diffMin}분 `;
      }
      if (diffSec > 0) {
        diffDate += `${diffSec}초 `;
      }
      return diffDate;
    },
    goToUrl(url) {
      window.open(url);
    },
    async getMetaData(url) {
      // "https://metadata-store.klaytnapi.com/8c793394-238d-5240-fab5-5190bad7e40b/6bfff1fc-ebf0-bc6f-be95-88d53ca1faf1.jpg"
      const result = {
        attributes: [],
        description: '',
        image: '',
        name: '',
      };
      url = `/event/token/nfts/metadata?uri=${encodeURI(url)}`;
      await api.get(url).then((res) => {
        result.image = res.data.image;
        result.attributes = res.data.attributes;
        result.description = res.data.description;
        result.name = res.data.name;
      }).catch((error) => {
        console.error(error);
      });
      return result;
    },
    changeBadgeList(name) {
      this.myNFTListType = name;
    },
    openPDF() {
      // eslint-disable-next-line no-restricted-globals
      window.open().location.href = './pdf/개인정보처리방침.pdf';
    },
  },
};
</script>
